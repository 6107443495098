.af-section {
  position: relative;
  min-height: 100%;
  @include rem(padding-top, 40px); // header height
  @include rem(padding-bottom, 100px);

  $sectionPadding: 110px;
  @include rem(padding-top, $sectionPadding/2);
  @include media-breakpoint-up(md) {
    @include rem(padding-top, $sectionPadding);
  }

  // Modifiers
  &--blue {
    background: $marine-blue;
    color: $white;
  }
  &--start {
    @include rem(padding-top, 0px); // header height not visible here
  }

  // subelements
  &__headline {
    @include rem(margin-top, 0);
  }
  &__text {
    @include rem(margin-bottom, 16px);
    @include rem(font-size, 17px);

    color: $charcoal-grey;

    &--intro {
      color: inherit;
      @include rem(margin-bottom, 30px);
      @include media-breakpoint-up(sm) {
        @include rem(margin-bottom, 90px);
      }

    }
  }
}



//
.af-section {
  background-size: cover;
  //background-size: 100%;
  //background-size: auto;
  background-position: center;
  background-repeat: no-repeat;




  &--start {
    background-image: url("../img/bg-shapes-start.svg");
  }
  &--aboutme {
    background-image: url("../img/bg_aboutme.svg");
  }
//   &--projects {
//     background-image: url("../img/bg_projekte.svg");
//     background-size: auto;
//   }
}
