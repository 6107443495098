// http://zerosixthree.se/detecting-media-queries-with-javascript/

@mixin define-breakpoint($name) {
    &:after {
        content: $name;
    }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

body {
    &:after {
        display: none;
    }
    @include media-breakpoint-down(sm) {
        @include define-breakpoint("xs");
    }
    @include media-breakpoint-up(sm) {
        @include define-breakpoint("sm");
    }
    @include media-breakpoint-up(md) {
        @include define-breakpoint("md");
    }
    @include media-breakpoint-up(lg) {
        @include define-breakpoint("lg");
    }
    @include media-breakpoint-up(xl) {
        @include define-breakpoint("xl");
    }
}
