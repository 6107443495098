.af-site-footer {
  background: $marine-blue;
  @include rem(padding, 30px 0);

  &__link {
    color: $white;
    text-decoration: none;
    //opacity: 1;
    transition: color $speed_fast ease;
    &:hover {
      //opacity: 0.5;
      color: $orange-yellow;
    }

  }
}