.af-contact {
    &__mapcontainer {
        transition: opacity $speed_normal ease-in-out;
        opacity: 1;
        @include rem(height, 240px);
        @include rem(margin-bottom, 40px);
        @include media-breakpoint-up(sm) {
            @include rem(height, 260px);
        }
        @include media-breakpoint-up(lg) {
            @include rem(height, 200px);
            @include rem(margin-bottom, 75px);
        }
        @include media-breakpoint-up(xl) {
            @include rem(height, 230px);
        }
        // fade in effect
        &--is-geocoding {
            opacity: 0;
        }
    }
    &__headline {
        display: block;
        @include rem(font-size, 21px);
        @include barlow('semibold');
        @include rem(margin-bottom, 20.5px);
        @include media-breakpoint-up(lg) {
            @include rem(font-size, 29px);
        }
    }
    &__adress {
        font-style: normal;
        @include rem(font-size, 15px);
        @include rem(line-height, 20.5px);
        &--mobile {
            @include rem(margin-bottom, 30px);
        }
    }
    &__submit {
        margin-top: 50px;
        color: $orange-yellow;
        background: 0;
        border: 0;
        width: 100%;
        font-size: 19px;
        cursor: pointer;
        @include media-breakpoint-up(lg) {
            width: auto;
            font-size: inherit;
            margin-top: 150px;
        }
    }
}
