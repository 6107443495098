.af-accordion {
    $accordionSpeed: $speed_fast * 1.2;
    &__item {
        @include af-box-shadow;
        @include rem(margin-bottom, 15px);
        transition: opacity $accordionSpeed ease-out;

        // active states
        &--open {
            .af-accordion__state-indicator {
                $rotation: 45deg * 3;
                &:before {
                    transform: rotate(0 + $rotation);
                }
                &:after {
                    transform: rotate(90 + $rotation);
                }
            }
        }
        // filter show/hide
        &--hidden {
            opacity: 0.1;
        }
    }

    &__title {
        background-color: $marine-blue;
        color: $white;
        @include rem(font-size, 20px);
        @include rem(letter-spacing, 0.7px);
        @include rem(padding, 7px 55px 10px 15px);
        @include barlow("semibold");
        line-height: 1.14;
        position: relative;
        cursor: pointer;
    }

    &__content {
        transition: all $speed_normal ease;
        background-color: $white;
        max-height: 0;
        overflow: hidden;
    }

    &__innercontent {
        @include rem(padding, 20px);
    }

    &__state-indicator {
        $size: 30px * 0.75;
        position: absolute;
        @include rem(right, $size * 0.7);
        top: 50%;
        @include rem(width, $size);
        @include rem(height, $size);
        @include rem(margin-top, $size/-2);
        display: block;

        &:before,
        &:after {
            $width: 3px;
            background: $white;
            transition: all $accordionSpeed ease-out;
            content: "";
            position: absolute;
            display: block;
            width: $width;
            height: $size;
            top: 0;
            left: 50%;
        }
        &:after {
            transform: rotate(90deg);
        }
    }
    &__image {
        width: 100%;
        background-color: $border-grey;

        @include rem(margin-bottom, $grid-gutter-width);

        overflow: hidden;

        @include media-breakpoint-up(sm) {
            width: 480px;
            height: 270px;
        }
        @include media-breakpoint-up(md) {
            width: 660px;
            height: 371px;
        }
        @include media-breakpoint-up(lg) {
            width: 740px;
            height: 416px;
        }

        @include media-breakpoint-up(xl) {
            width: 890px;
            height: 500px;
        }
    }
}
