.af-site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  @include rem(padding-bottom, 19px);
  @include rem(padding-top, 26px);
  background: rgba(255,255,255,0.98);
  background: rgb(255,255,255);
  opacity: .97;
  transition: all $speed_fast ease-out;

  &--initial {
    position: absolute;
    top: -75px;
    opacity: 0.75;
  }
}