.form-control {
    background: none;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    color: $white;
    width: 100%;
    border-radius: 0;
    @include rem(font-size, 15px);
    //background: rgba(255,255,255, 0.05);
    @include rem(padding-left, 2px);
    @include rem(padding-bottom, 1px);

    &::placeholder {
        transition: all $speed_fast ease;

        color: rgba(255, 255, 255, 0.8);
    }

    &:focus {
        color: $white;
        outline: none;
        background: none;
        box-shadow: none;
        border: none;
        //border-bottom: 1px solid;
        border-bottom: 1px solid rgba(255, 255, 255, 0.93);
        &::placeholder {
            color: rgba(255, 255, 255, 0.1);
            font-size: 80%;
        }
    }
}
.form-row {
    &__sweet {
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: block;
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
    left: -9999px;
    top: 0;
    }
}
textarea {
    resize: vertical;
}
