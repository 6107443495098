p {
    @include rem(font-size, 19px);
    @include rem(letter-spacing, 0.3px);
    margin: 0;
}



h1,
h2,
h3,
h4,
h5,
h6 {
    color: $marine-blue;
    @include rem(letter-spacing, 0.7px);
}

h1 {
    @include rem(font-size, 35px);
    @include media-breakpoint-up(md) {
        @include rem(font-size, 40px);
    }
}

h2 {
    @include rem(font-size, 32px);
}

.link {
    color: inherit;
    &--tel {
        text-decoration: none;
        padding: 5px 0;
        display: inline-block;
    }
    &--no-underline {
        text-decoration: none;
    }
}

@mixin typo-numbers() {
    @include barlow("thin");
    @include rem(font-size, 25px);
    @include rem(letter-spacing, 0.4px);

    @include media-breakpoint-up(md) {
        @include rem(font-size, 95px);
        @include rem(letter-spacing, 1.7px);
    }
}

@mixin typo-h1() {
    @include barlow("bold");
    @include rem(font-size, 37px);

    @include media-breakpoint-up(md) {
        @include rem(font-size, 40px);
        @include rem(letter-spacing, 0.7px);
    }
}
@mixin typo-h1-neg() {
    @include typo-h1();
    color: $white;
}

@mixin typo-h2() {
    @include barlow("semibold");
    @include rem(font-size, 21px);
    @include rem(letter-spacing, 0.5px);

    @include media-breakpoint-up(md) {
        @include rem(font-size, 29px);
        @include rem(letter-spacing, 0.7px);
    }
}
@mixin typo-h3() {
    @include barlow("semibold");
    @include rem(font-size, 19px);

    @include media-breakpoint-up(md) {
        @include rem(font-size, 22px);
        @include rem(letter-spacing, 0.4px);
    }
}

// COPY
@mixin typo-copy() {
    @include barlow();
    @include rem(font-size, 19px);
    color: $charcoal-grey;

    @include media-breakpoint-up(md) {
        @include rem(font-size, 15px);
        @include rem(letter-spacing, 0.3px);
    }
}
@mixin typo-copy--neg() {
    @include typo-copy();
    @include rem(letter-spacing, 0.2px);
    @include media-breakpoint-up(md) {
        @include rem(letter-spacing, 0.3px);
    }
    color: $white;
}
@mixin typo-copy--accent() {
    @include typo-copy();
    @include barlow("bold");
}

// INTRO
@mixin typo-intro() {
    @include barlow();
    @include typo-copy();

    @include media-breakpoint-up(md) {
        @include rem(font-size, 17px);
        @include rem(letter-spacing, 0.3px);
    }
}
@mixin typo-intro--neg() {
    @include typo-intro();
    @include rem(font-size, 21px);
    @include rem(letter-spacing, 0.4px);
    @include media-breakpoint-up(md) {
        @include rem(font-size, 19px);
        @include rem(letter-spacing, 0.3px);
    }
}
@mixin typo-button() {
    @include barlow("bold");
    @include rem(font-size, 15px);
    @include rem(letter-spacing, 0.3px);
    color: $orange-yellow;
}
@mixin typo-category() {
    @include barlow("bold");
    @include rem(font-size, 13px);
    @include rem(letter-spacing, 0.5px);
    color: $charcoal-grey;
}
//@mixin typo-category--active() {
//    @include typo-category();
//    color: $orange-yellow;
//}

textarea {
    font-family: inherit;
    min-height: 100px;
}
