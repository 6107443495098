.af-aboutme {
    &__imgwrap {
        text-align: right;
        @include rem(padding-top, 10px);
    }

    &-card {
        cursor: pointer;
        display: table;
        width: 100%;
        height: 100%;
        text-align: center;
        color: $white;
        @include barlow("medium");
        @include af-box-shadow;
        @include rem(min-height, 80px);
        @include rem(padding, 10px);

        @include media-breakpoint-up(md) {
            @include rem(min-height, 170px);
        }

        &__wrap {
            @include rem(margin-bottom, $grid-gutter-width);
            @include media-breakpoint-up(md) {
                @include rem(height, 315px);
            }
            @include media-breakpoint-up(xl) {
                @include rem(height, 275px);
            }
        }

        @include rem(letter-spacing, 0.4px);
        background-color: $marine-blue;
        transition: background-color $speed_fast ease-out;

        &--active {
            background-color: $tangerine;
        }

        &__title,
        &__list {
            display: none;
            width: 100%;
            vertical-align: middle;

            &--active {
                display: table-cell;
            }
        }

        &__list {
            list-style: none;
            padding: 0;
            margin: 0;

            &--active {
                width: auto;
            }
        }

        // todo: BEM
        &__item {
            @include rem(padding, 12px 0);
        }
    }
}

// todo: move to correct file
.af-section {
    &--aboutme {
        background-size: 1500px;
        background-position: -715px -130px;

        @include media-breakpoint-up(md) {
            background-size: 2000px;
            background-position: -670px -200px;
        }

        &__lower {
            @include rem(margin-top, 90px);

            @include media-breakpoint-up(md) {
                @include rem(margin-top, 250px);
            }
        }
    }
}
