// todo: make bem style

.af-section--imprint {
    line-height: 1.35;
    p,
    ul,
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include typo-copy--neg();
        hyphens: auto;
    }
    p,
    ul,
    a {
        font-size: 0.9375rem;
        margin-bottom: 0.5rem;

        @include media-breakpoint-up(md) {
            text-align: justify;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white;
    }
    h4 {
        margin-bottom: 0;
    }
    ul {
        list-style: none;
        padding-left: 0;
        margin-left: 0;
    }
    h1 {
        @include typo-h1-neg();
    }
    h4 {
        @include barlow(bold);
    }
}
.af-expandable__trigger {
    font-size: 16px;
}
