.af-expandable {
    &__trigger {
        cursor: pointer;
        margin-bottom: 15px;
        &:before {
            content: '+';
            display: inline-block;
            width: 16px;
            font-weight: 700;
        }
        &--expanded {
            &:before {
                content: '-';
            }
        }
    }
    &__content {
        transition: all $speed_normal ease;
        max-height: 0;
        overflow: hidden;
    }
}
