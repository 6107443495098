.site-cookiebanner {
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0;
    left: 0;
    padding: 20px;
    text-align: center;
    border-top: 2px solid $marine-blue-rgba;
    &__infolink {
        color: $deep-sea-blue;
    }

    &__confirm {
        background-color: #fff;
        color: $marine-blue;
        padding: 5px 10px;
        font-weight: bold;
        text-decoration: none;
        display: inline-block;
        margin-top: 5px;
    }
    &--hidden {
        display: none;
    }
}
