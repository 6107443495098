

$path-to-fonts: '../fonts/barlow-v1-latin/';

/* barlow-100 - latin */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Barlow Thin'), local('Barlow-Thin'),
         url('#{$path-to-fonts}barlow-v1-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('#{$path-to-fonts}barlow-v1-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* barlow-regular - latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Barlow Regular'), local('Barlow-Regular'),
         url('#{$path-to-fonts}barlow-v1-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('#{$path-to-fonts}barlow-v1-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* barlow-500 - latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Barlow Medium'), local('Barlow-Medium'),
         url('#{$path-to-fonts}barlow-v1-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('#{$path-to-fonts}barlow-v1-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* barlow-700 - latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Barlow Bold'), local('Barlow-Bold'),
         url('#{$path-to-fonts}barlow-v1-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('#{$path-to-fonts}barlow-v1-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* barlow-800 - latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 800;
    src: local('Barlow ExtraBold'), local('Barlow-ExtraBold'),
         url('#{$path-to-fonts}barlow-v1-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('#{$path-to-fonts}barlow-v1-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }



@mixin barlow($weight: 500) {
  @if ($weight == 'thin') {
    $weight: 100;
  }
  // default is regular -> 400
  @if ($weight == 'medium') {
    $weight: 500;
  }
  @if ($weight == 'semibold') {
    $weight: 600;
  }
  @if ($weight == 'bold') {
    $weight: 700;
  }
  font-weight: $weight;
}
