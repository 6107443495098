.af-projects {

    &__navigation__link {
        text-transform: uppercase;
        display: inline-block;
        text-decoration: none;
        @include typo-category();
        @include rem(padding, 15px 10px);
        @include rem(margin-right, 10px);
        transition: color $speed_fast ease;

        &--first {
            @include rem(margin-left, 0);
        }
        &--active,
        &:hover {
            color: $orange-yellow;
        }
    }
    &__bigimageContainer {
        position: relative;
        @include rem(margin-bottom, 20px);
        @include media-breakpoint-up(md) {
            @include rem(margin-bottom, 10px);
        }
    }
    &__bigimage {
        &.fadeOutImg {
            -webkit-animation: fadeinout 2s linear forwards;
            animation: fadeinout 2s linear forwards;

            @include keyframes(fadeinout) {
                0% {
                    opacity: 1;
                }
                20% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }
    }
    &__bigimageContainer,
    &__thumb {
        border: 1px solid $border-grey;
    }
    &__thumb-wrap {
        display: none;
        @include media-breakpoint-up(md) {
            @include rem(margin, 0);
            display: block;
        }
    }
    &__thumb {
        @include rem(margin-bottom, 20px);
    }
}
