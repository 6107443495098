// fallback to px units
$rem-fallback: true;




$prefix:'-moz-', '-webkit-', '-o-', '-ms-', '';
// ********************
// fadeIn
// ********************
@mixin keyframe-fadeIn {
  0%   { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-webkit-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-o-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-ms-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@keyframes fadeIn {
  @include keyframe-fadeIn;
}
@mixin fadeIn( $arg ) {
  display:block;
  $keyframe-name:fadeIn;
  $duration:$arg;
  @each $p in $prefix {
    #{$p}animation:$keyframe-name $duration;
  }
}

@mixin box-shadow($horizontal: 0px, $vertical: 1px, $blur: 2px, $color: #CCC) {
  -webkit-box-shadow: $horizontal $vertical $blur $color;
  -moz-box-shadow: $horizontal $vertical $blur $color;
  box-shadow: $horizontal $vertical $blur $color;
}

@mixin af-box-shadow() {
  @include box-shadow(0, 3px, 6px, rgba(0, 0, 0, 0.3));
}

