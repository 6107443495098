::-moz-selection {
  background: rgb(250, 165, 0);
  color: $marine-blue;
}

::selection {
  background: $orange-yellow;
  color: $marine-blue;
}

html,
body {
  margin: 0;
  padding: 0;

  font-family: $af-font, Helvetica, sans-serif;
  // todo: use nice fontface / font-weight
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: $charcoal-grey;
  min-width: 360px;
}
img {
  display: block;
  max-width: 100%;
}
