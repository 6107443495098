.af-card {
    text-align: center;
    line-height: 1;
    width: 100%;
    display: table;
    cursor: pointer;
    @include box-shadow(0, 3px, 6px, rgba(0, 0, 0, 0.5));
    @include rem(height, 90px);
    @include rem(margin-bottom, 10px);

    @include media-breakpoint-up(md) {
        @include rem(height, 180px);
        @include rem(margin-bottom, 0px);
    }

    // ading gradients
    // https://medium.com/@dave_lunny/animating-css-gradients-using-only-css-d2fd7671e759
    background-image: linear-gradient(to bottom, rgba(0, 255, 255, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient($deep-sea-blue, $deep-sea-blue);
    background-blend-mode: multiply,
    normal;
    position: relative;
    z-index: 100;

    &:before {
        transition: opacity $speed_veryslow ease-out;
        border-radius: inherit;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 255, 255, 0.5)), linear-gradient($deep-sea-blue, $deep-sea-blue);
        background-blend-mode: multiply, normal;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: -100;
    }

    &:hover {
        &:before {
            opacity: 1;
        }
    }

    &__title {
        display: table-cell;
        vertical-align: middle;
        @include barlow('thin');
        @include typo-numbers();

        @include media-breakpoint-up(md) {
            @include rem(padding-bottom, 20px);
        }
    }

    &__text {
        display: block;
        @include barlow('medium');
        @include rem(font-size, 21px);
        @include rem(letter-spacing, 0.5px);

        @include media-breakpoint-up(md) {
            @include rem(font-size, 22px);
            @include rem(letter-spacing, 0.4px);
        }
    }
}
