.af-scrollarrow {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='38'%3E%3Cpath fill='%23FAA500' fill-rule='evenodd' d='M18.69 29.57c.38-.38.43-1 .04-1.44-.35-.4-1.04-.4-1.42-.04l-6.81 6.54V1.02A1.01 1.01 0 0 0 9.5 0a1 1 0 0 0-1 1.02v33.6L1.69 28.1a1.03 1.03 0 0 0-1.42.04c-.38.4-.35 1.06.04 1.44l8.5 8.14c.46.42.99.35 1.38 0l8.5-8.14z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    position: absolute;
    @include rem(width, 60px);
    @include rem(height, 100px);
    @include rem(padding, 20px);
    @include rem(margin-left, -30px);
    @include rem(text-indent, -9999px);
    left: 50%;
    @include rem(height, -10px);
    @include rem(bottom, 15px);
    transition: all $speed_veryfast ease-in-out;
    opacity: 0.5;
    &:hover {
        opacity: 1;
        @include rem(bottom, 5px);
    }
}
