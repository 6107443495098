.af-menu {
    cursor: default;
    float: right;
    display: block;
    overflow: hidden;
    @include rem(margin-top, 6px);
    &__link {
        @include rem(text-indent, -9999px);
        display: block;
        float: left;
        @include rem(margin-right, 6px);
        background-color: $cloudy-blue;
        @include rem(width, 16px);
        @include rem(height, 13px);
        transition: background-color $speed_normal ease;
        &--active {
          background-color: $orange-yellow;
        }
    }
}
