.af-projectdetail {
  @include rem(font-size, 15px);
  @include rem(line-height, 1.8);
  @include rem(margin, 0);
  overflow: hidden;
  $keyWidthMd: 22%;
  $keyWidthXl: 17.5%;


  @include rem(margin-top, 20px);

  &__key {
    float: left;
    @include rem(padding-right, 10px);
    @include media-breakpoint-up(sm) {
      @include rem(padding-right, 5px);
    }
    @include media-breakpoint-up(md) {
      width: 45%;
    }
    @include media-breakpoint-up(xl) {
      width: 36%;
    }
    &--narrow {
      @include media-breakpoint-up(md) {
        width: auto;
        @include rem(padding-right, 20px);
      }

    }
    &--wide {
      @include media-breakpoint-up(md) {
        width: $keyWidthMd;
      }
      @include media-breakpoint-up(xl) {
        width: $keyWidthXl;
      }
    }
    &--boldOnSm {
      @include media-breakpoint-down(sm) {
        @include barlow('semibold');
      }
    }

  }
  &__value {
    break-inside: avoid;
    margin-left: 0;
    &--bold {
      @include barlow('semibold');
    }
    &--wide {
      float: left;
      width: 100% - $keyWidthMd;
      @include media-breakpoint-up(xl) {
        width: 100% - $keyWidthXl;
      }
    }
  }


  &--lower {
    @include rem(margin-top, 20px);
    @include media-breakpoint-up(md) {
      @include rem(margin-top, 0);
    }
  }


}
